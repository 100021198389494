import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"

import { ParallaxProvider } from 'react-scroll-parallax';

import EventThumb from "../components/event_thumb";

const EventiPage = ({data, location}) => {
  const eventi = data.allContentfulEvento.nodes
  

  return (
    <Layout location={location} title=''>
      
      <ParallaxProvider>
      
      <section className="bg-background pb-40 relative z-20">
        <h2 className="text-white text-center pb-20 relative top-10 tracking-wider">I nostri Workshop</h2>
        <div className="container max-w-screen-xl grid md:grid-cols-1 lg:grid-cols-3 gap-16">
        {eventi.map(evento => {
          return (
            <Link to={"/" + evento.slug} itemProp="url">
              <EventThumb titolo={evento.titolo} dataInizio={evento.data} coverImage={evento.coverImage.gatsbyImageData} />
            </Link>
          )
        })}
          
        </div>
      </section>

      </ParallaxProvider>
      
    </Layout>
  )
}

export default EventiPage

export const eventiQuery = graphql`
    query {
      allContentfulEvento(
        sort: {fields: dataInizio, order: DESC}
        filter: {tipologiaEvento: {elemMatch: {titolo: {eq: "Workshop"}}}}
        ) {
        nodes {
          coverImage {
            gatsbyImageData(
              quality: 80
              layout: FULL_WIDTH
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP]
              aspectRatio: 1
            )
          }
          titolo
          tipologiaEvento {
            titolo
          }
          slug
          prezzo
          data
          dataInizio(formatString: "DD-MM-YY")
        }
      }
    }
  `